import React from 'react';
import { Blank } from 'grommet-icons';

const IconBook = (props) => {
  return (
    <Blank
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 266 168"
      style={{ strokeWidth: 0 }}
      {...props}
    >
      <path d="M.048 83.953v84h265.999v-168H.048v84zm126.952 0v72H12.048v-144H127v72zm127.047 0v72H139v-144h115.047v72z" />
      <path d="M35 49v6h66V43H35v6zM35 83.953v6h66v-12H35v6zM35 119v6h66v-12H35v6zM161.19 48.714l.286 5.81 33.238.286 33.333.19V43h-67.142l.285 5.714zM161.19 83.762l.286 5.714 33.238.286 33.333.286V77.953h-67.142l.285 5.81zM161.19 118.714l.286 5.81 33.238.286 33.333.19v-12h-67.142l.285 5.714z" />
    </Blank>
  );
};

export default IconBook;
